.dx-popup-content {
  max-height: 250px !important;
  overflow-y: auto !important;
}
.dx-htmleditor-content a {
  color: #337ab7;
}

.dx-toolbar-menu-section .dx-list-item-content,
.dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button .dx-button-content,
.dx-toolbar-menu-section .dx-toolbar-hidden-button-group .dx-button .dx-button-content,
.dx-toolbar-menu-section .dx-toolbar-item-auto-hide {
  padding: 5px;
}
