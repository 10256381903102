.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(1deg);
  background-color: rgb(255, 255, 255);
  cursor: -webkit-grab;
  cursor: grab;
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.column-drag-handle {
  cursor: grab;
  padding: 5px;
}

.column-drag-handle:hover {
  font-weight: 800;
  transition: 100ms ease-in-out;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
}
.cards-drop-preview-form {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
}

.drop-zone-container {
  transition: background-color 0.2s ease;
  border: 5px solid #348;
  background-color: #80bdff;
  margin: 20px;
}

.card-ghost-test{
  background-color: cyan;
}